#order {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#order td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#order tr:nth-child(even) {
  background-color: #f2f2f2;
}

#order tr:hover {
  background-color: #ddd;
}

#order th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #cc8d2e;
  padding-left: 8px;
  color: white;
}
